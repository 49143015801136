import React from "react";
import Logo from "../assets/logo.svg";
import DiscordLogo from "../assets/discord.svg";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { SelectDropdown } from "baseui/select";
import { Menu, StatefulMenu } from "baseui/menu";
import { StatefulPopover } from "baseui/popover";
import burger from "../assets/burger.svg";

export const DISCORD_INVITE_URL = "https://discord.com/invite/Zzf2ekV6sJ";
export const HAMBURGER_NAV_MAX_WIDTH = 817; // px

interface NavItemData {
  label: string;
  path: string;
  disabled?: boolean;
  hamburgerOnly?: boolean;
}

export const VerticalBar = () => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        width: "2px",
        height: "48px",
        backgroundColor: "#D1D1D1",
        margin: "0 22px",
      })}
    ></div>
  );
};

export const DiscordButton = () => {
  const [css, $theme] = useStyletron();

  return (
    <img
      onClick={() => {
        window.open(DISCORD_INVITE_URL, "_blank");
      }}
      className={css({
        cursor: "pointer",
      })}
      width={"50px"}
      src={DiscordLogo}
      alt="discord"
    />
  );
};

export const CCPC = () => {
  const [css, $theme] = useStyletron();

  return (
    <img
      onClick={() => {
        window.location.href = "/";
      }}
      className={css({
        cursor: "pointer",
      })}
      src={Logo}
      alt="logo"
    />
  );
};

export const NavItem = (props: {
  children: React.ReactNode;
  disabled?: boolean;
  path?: string;
}) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        verticalAlign: "center",
        justifyContent: "center",
        flexShrink: 0,
        color: props.disabled ? "#BCBCBC" : "#000",
        textAlign: "center",
        fontFamily: "Helvetica",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        userSelect: "none",
        cursor: props.disabled ? "not-allowed" : "pointer",
        letterSpacing: "-1px",
      })}
      onClick={() => {
        if (!props.disabled && props.path) {
          window.location.href = props.path;
        }
      }}
    >
      {props.children}
    </div>
  );
};

export const NavHamburger = (props: { items: Array<NavItemData> }) => {
  const [css, $theme] = useStyletron();

  return (
    <StatefulPopover
      content={
        <Menu
          items={props.items.map((item) => {
            return {
              label: (
                <span
                  className={css({
                    color: item.disabled ? "#BCBCBC" : "#000",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                  })}
                  onClick={() => {
                    if (!item.disabled && item.path) {
                      window.location.href = item.path;
                    }
                  }}
                >
                  {item.label}
                </span>
              ),
              disabled: !!item.disabled,
              key: item.label,
            };
          })}
        />
      }
    >
      <Button
        color="white"
        colors={{
          backgroundColor: "white",
          color: "white",
        }}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              backgroundColor: "white",
              border: "none",
              padding: "2px",
              cursor: "pointer",
            }),
          },
        }}
      >
        <img width={"40px"} src={burger} alt="burger" />
      </Button>
    </StatefulPopover>
  );
};

export const MainNav = () => {
  const [css, $theme] = useStyletron();
  const [hamburgerNavOn, setHamburgerNavOn] = React.useState(false);

  const navItems: NavItemData[] = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Solutions",
      path: "/solutions",
      disabled: true,
    },
    {
      label: "Meetings",
      path: "/meetings",
      disabled: true,
    },
    {
      label: "Leaderboards",
      path: "/leaderboards",
      disabled: true,
    },
    {
      label: "Discord",
      path: DISCORD_INVITE_URL,
      hamburgerOnly: true,
    },
  ];

  const hamburgerOnlyCount = navItems.filter(
    (item) => item.hamburgerOnly
  ).length;

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= HAMBURGER_NAV_MAX_WIDTH) {
        setHamburgerNavOn(true);
      } else {
        setHamburgerNavOn(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        })}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
          })}
        >
          <CCPC />
          <VerticalBar />
          {!hamburgerNavOn &&
            navItems.map((item, index) => {
              if (item.hamburgerOnly) {
                return null;
              }
              return (
                <>
                  <NavItem
                    key={index}
                    path={item.path}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </NavItem>
                  {index !== navItems.length - 1 - hamburgerOnlyCount && (
                    <VerticalBar />
                  )}
                </>
              );
            })}
        </div>
        <div
          className={css({
            marginRight: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          })}
        >
          {hamburgerNavOn ? (
            <NavHamburger items={navItems} />
          ) : (
            <DiscordButton />
          )}
        </div>
      </div>

      {/* Red bar */}
      <div
        className={css({
          height: "4px",
          flexShrink: 0,
          width: `calc(min(100vw, 900px))`,
          backgroundColor: "#F20000",
        })}
      ></div>
    </div>
  );
};
