import React from "react";
import { useStyletron } from "baseui";
import { MainNav } from "./components/NavBar";

export const ContentWrapper = (props: { children: React.ReactNode }) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        minHeight: "100vh",
        overflow: "clip",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "101vh",
        })}
      >
        <div
          className={css({
            display: "flex",
            maxWidth: "900px",
            width: "100vw",
            border: "1px solid #BCBCBC",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            height: "100%",
            padding: "10px",
          })}
        >
          <div
            className={css({
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontFamily: "Helvetica",
            })}
          >
            <div
              className={css({
                alignItems: "baseline",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              })}
            >
              <MainNav />
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageHeading = (props: {
  children: React.ReactNode;
  size?: "large" | "medium";
}) => {
  const [css, $theme] = useStyletron();

  let fontSize;
  switch (props.size) {
    case "large":
      fontSize = "60px";
      break;
    case "medium":
      fontSize = "36px";
      break;
    default:
      fontSize = "60px";
      break;
  }

  return (
    <div>
      <div
        className={css({
          color: "#000",
          fontFamily: "Helvetica",
          fontSize,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: props.size === "large" ? "-4px" : "-2px",
          marginBottom: "4px ",
          zIndex: 1,
          wordBreak: "break-word",
          maxWidth: "100%",
          overflowWrap: "break-word",
        })}
      >
        {props.children}

        <div
          className={css({
            position: "relative",
            top: props.size === "large" ? "-4px" : "-2px",
            width: `calc(min(100vw, 900px))`,
            height: "4px",
            flexShrink: 0,
            zIndex: -1,
            backgroundColor: "#EDEDED",
          })}
        ></div>
      </div>
    </div>
  );
};

function App() {
  return (
    <ContentWrapper>
      <PageHeading>Welcome</PageHeading>
      <p>
        This is the homepage for the{" "}
        <strong> Carleton Competitive Programming Club. </strong>
        Our goal is to provide a space for fellow students to practice their
        Competitive Programming and Data Structures & Algorithms skills by
        solving problems together. We also aim to participate on behalf of
        Carleton University in competitions such as the{" "}
        <strong> ACM-ICPC.</strong>
      </p>

      <p>
        We are a new club this year, and are currently looking for members
        to join us. We welcome students of all skill levels, from beginners to
        experts. From seasoned competitors to those who are just curious about
        Competitive Programming are greatly encouraged to join us.
      </p>

      <p>
        This website is also a work in progress and will be updated throughout
        the upcoming year. If you're interested in joining us, please join our
        official Discord server by clicking the Discord logo on the top right of
        the page.
      </p>

      <PageHeading size="medium">
        What even is Competitive Programming?
      </PageHeading>

      <p>
        Competitive Programming may sound intimidating at first. <br />
        You may be thinking, "programming is already hard enough, why should I
        try to do it competitively?" <br />
        <br />
        However, Competitive Programming is not only about competing against
        other people. It's mainly about challenging yourself to solve problems
        that you may have never seen before in the most efficient way possible.
        It's about learning new algorithms and data structures that you may have
        never heard of before. It's about coming up with creative solutions to
        problems that may seem impossible at first.{" "}
        <strong>
          It's about learning how to think critically and logically in the
          context of Computer Science.
        </strong>
      </p>

      <p>
        Practicing competitive programming will not only help you become a
        better programmer, but its teachings will be immensely useful throughout
        your Computer Science or Software Engineering courses, especially in the
        most challenging courses you'll take throughout your degree.
        Additionally if you're interested in pursuing a career in software
        development, competitive programming will help you prepare for technical
        interviews and beyond, especially taking the communication and teamwork
        skills you'll gain from participating in competitions and working with
        others in the club.
      </p>

      <p>
        Competitive programming, at its core, is a highly engaging activity that
        fosters problem-solving skills in computer science enthusiasts.
        Participants are presented with a diverse set of computational
        challenges encompassing areas like mathematics, data structures,
        algorithms, graphs, and dynamic programming. These problems vary in
        complexity, ranging from relatively straightforward to exceptionally
        intricate. The primary goal of contestants is to devise efficient and
        correct programs that can solve these problems within specified
        constraints.
      </p>

      <p>
        To succeed in competitive programming, participants must develop strong
        algorithmic thinking skills. This involves breaking down complex
        problems into smaller, more manageable steps and devising optimized
        algorithms to tackle them. The ability to think critically, creatively,
        and systematically is essential in crafting efficient solutions that
        outperform brute-force approaches.
      </p>

      <p>
        In competitive programming contests, time and resource constraints play
        a vital role. Each problem comes with a limited time frame for
        resolution, which puts contestants under pressure to devise optimal
        solutions quickly. In this environment, time complexity matters
        significantly, as inefficient algorithms may not run within the time
        limits, leading to unsuccessful submissions. This time pressure
        simulates real-world scenarios where programmers often need to deliver
        solutions under tight deadlines.
      </p>

      <p>
        One of the most prestigious and well-known competitive programming
        competitions is the ACM International Collegiate Programming Contest
        (ICPC). It is an annual multi-tiered competition that brings together
        teams of students from universities worldwide. The ICPC challenges
        participants with a series of complex problems that require rigorous
        problem-solving and algorithmic skills. In regional contests, teams
        compete to qualify for the ICPC World Finals, where the best teams from
        around the globe vie for the championship.
        <br />
        <br />
        <strong>
          Carleton University has not sent a team to the ICPC in over 7 years
          and we hope to change that this year.
        </strong>
      </p>
    </ContentWrapper>
  );
}

export default App;
